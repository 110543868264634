import React from "react"
import { Link } from "gatsby"

const PageSection = ({ title, titleLink, titleIcon, sectionId, sectionClass, sectionStyle, children }) => {
  sectionId = sectionId || null

  return (
    <section
      id={sectionId}
      className={
        "page-section " +
        (sectionClass ? sectionClass : "")
      }
      style={sectionStyle}
    >
      <div className="page-section-row">
        {title && (
          titleLink ? (
            <h2 className="page-section-heading">
              <Link to={titleLink} className="page-section-heading-link">
                <span>{title}</span>
                {titleIcon && titleIcon === "right-arrow" && (
                  <svg xmlns="http://www.w3.org/2000/svg" className="svg-right-arrow" viewBox="0 0 30 30" role="img" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M10 6 L18 15 L10 24"></path></svg>
                )}
              </Link>
            </h2>
          ) : (
            <h2 className="page-section-heading">{title}</h2>
          )
        )}

        {children}
      </div>
    </section>
  )
}

export default PageSection
