import React from "react"
import Seo from "./seo"

const BlankLayout = ({ isHomePage, pageTitle, metaDescription, bodyClass, headerClass, children }) => {

  return (
    <div>

      <Seo title={pageTitle} description={metaDescription} />

      <main>{children}</main>

    </div>
  )
}

export default BlankLayout
