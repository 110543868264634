import React from "react"
import { Link } from "gatsby"
import BlankLayout from "../components/blank-layout"
import PageSection from '../components/page-section'


const Subscribe = ({ data, location }) => {
  return (
    <BlankLayout location={location} pageTitle="Subscribe" bodyClass="page-subscribe">

      <PageSection title="Subscribe" className={"red curved-top light-text"} />

      <PageSection>        
        <p>Please Login or Subscribe</p>
        <p>To access our videos please login or take out a subscription.</p>
      </PageSection>

    </BlankLayout>
  )
}

export default Subscribe
